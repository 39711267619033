import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import enNavbar from '../locales/en/navbar.json';
import siNavbar from '../locales/si/navbar.json';
import taNavbar from '../locales/ta/navbar.json';
import enFooter from '../locales/en/footer.json';
import siFooter from '../locales/si/footer.json';
import taFooter from '../locales/ta/footer.json';
import enHome from '../locales/en/home.json';
import siHome from '../locales/si/home.json';
import taHome from '../locales/ta/home.json';
import enAboutUs from '../locales/en/aboutUs.json';
import siAboutUs from '../locales/si/aboutUs.json';
import taAboutUs from '../locales/ta/aboutUs.json';
import enAboutDiabetes from '../locales/en/aboutDiabetes.json';
import siAboutDiabetes from '../locales/si/aboutDiabetes.json';
import taAboutDiabetes from '../locales/ta/aboutDiabetes.json';
import enSelfAssessment from '../locales/en/selfAssessment.json';
import siSelfAssessment from '../locales/si/selfAssessment.json';
import taSelfAssessment from '../locales/ta/selfAssessment.json';
import enCommon from '../locales/en/common.json';
import siCommon from '../locales/si/common.json';
import taCommon from '../locales/ta/common.json';
import enContact from '../locales/en/contact.json';
import siContact from '../locales/si/contact.json';
import taContact from '../locales/ta/contact.json';
import enLibrary from '../locales/en/library.json';
import siLibrary from '../locales/si/library.json';
import taLibrary from '../locales/ta/library.json';
import enProgram from '../locales/en/programs.json';
import siProgram from '../locales/si/programs.json';
import taProgram from '../locales/ta/programs.json';
import enTemplate from '../locales/en/template.json';
import siTemplate from '../locales/si/template.json';
import taTemplate from '../locales/ta/template.json';


// Resources for each language and namespace
const resources = {
  en: {
    navbar: enNavbar,
    footer: enFooter,
    home: enHome,
    aboutUs: enAboutUs,
    aboutDiabetes: enAboutDiabetes,
    selfAssessment: enSelfAssessment,
    common: enCommon,
    contact: enContact,
    library: enLibrary,
    program: enProgram,
    template: enTemplate,
  },
  si: {
    navbar: siNavbar,
    footer: siFooter,
    home: siHome,
    aboutUs: siAboutUs,
    aboutDiabetes: siAboutDiabetes,
    selfAssessment: siSelfAssessment,
    common: siCommon,
    contact: siContact,
    library: siLibrary,
    program: siProgram,
    template: siTemplate,
  },
  ta: {
    navbar: taNavbar,
    footer: taFooter,
    home: taHome,
    aboutUs: taAboutUs,
    aboutDiabetes: taAboutDiabetes,
    selfAssessment: taSelfAssessment,
    common: taCommon,
    contact: taContact,
    library: taLibrary,
    program: taProgram,
    template: taTemplate,
  },
};

// Initialize i18n
i18n
  .use(initReactI18next) // Connect i18next with React
  .init({
    resources,
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback if language not available
    ns: [
      'navbar',
      'footer',
      'home',
      'aboutUs',
      'library',
      'contact',
      'program',
      'common',
      'diabetes',
      'selfAssessment',
      'template',
    ], // Namespaces used
    interpolation: {
      escapeValue: false, // React escapes values by default
    },
    react: {
      useSuspense: false, // Disable suspense for simplicity
    },
  });

export default i18n;
