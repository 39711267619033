import { Link } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BottomBar = () => {
  const { t } = useTranslation('footer');

  const CurrentYear = new Date().getFullYear();
  return (
    <div className="flex flex-col items-center justify-between py-11 sm:flex-row">
      <div className="font-poppins text-center text-sm font-normal leading-[2.1rem] tracking-[0.1rem] text-[#5D5E76] sm:text-left">
        ©{CurrentYear}{' '}
        {t(
          'footer.bottomBar.copyRides',
          'Copyright Suwa Diviya. All Rights Reserved.'
        )}
      </div>
      <div className="mt-[2rem] flex gap-6 sm:mt-0 sm:gap-3">
        <Link
          to="/privacy-policy"
          className="font-poppins text-end text-sm font-semibold leading-[2.1rem] tracking-[0.1rem] text-[#2E2E38] underline"
        >
          {t('footer.bottomBar.privacy', 'Privacy Policy')}{' '}
        </Link>
        <Link
          to="/terms"
          className="font-poppins text-end text-sm font-semibold leading-[2.1rem] tracking-[0.1rem] text-[#2E2E38] underline"
        >
          {t('footer.bottomBar.terms', 'Terms of Use')}
        </Link>
      </div>
    </div>
  );
};

export default BottomBar;
