import React, { useEffect } from 'react';
import BottomBar from './components/BottomBar';
import ContentBar from './components/ContentBar';
import i18next from 'i18next';

type Props = {};

const Footer = () => {
  useEffect(() => {
    const storedLang = sessionStorage.getItem('language');
    if (storedLang) {
      i18next.changeLanguage(storedLang);
    }
  }, []);
  return (
    <div>
      <div className="px-[6rem]">
        <ContentBar />
        <div className="h-[0.1rem] w-full bg-warning-orange"></div>
        <BottomBar />
      </div>
    </div>
  );
};

export default Footer;
