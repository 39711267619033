import React, { ReactNode, useEffect } from 'react';
import { useLocation } from '@reach/router';
// import NavBar from '../common/navBar/NavBar';
import Footer from '../common/footer/Footer';
import NavBarHeader from '../common/navBar/components/NavBarHeader';

type Props = {
  children: ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
  const location =
    typeof window !== 'undefined' ? useLocation() : { pathname: '/' };

  // Scroll to top on location change
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return (
    <div>
      <div className="fixed top-0 z-[1000] hidden w-full lg:grid">
        <NavBarHeader />

        {/* <NavBar /> */}
      </div>
      {/* Main content area with initial top margin */}
      <main className="mt-[8rem] sm:mt-[4.2rem] lg:mt-[14rem]">{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
