import React, { useState } from 'react';
import XlLogo from '../../logo/XlLogo';
import { Link } from 'gatsby';
import CustomButton from '../../buttons/CustomButton';
import { MdEmail } from 'react-icons/md';
import axios from 'axios';
import { toast } from 'react-toastify';
import { formIds } from '../../../../utils/formIds';
import { FaSpinner } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import BookFreeProgram from '../../modals/BookFreeProgram';

// ReusableLink component with improved readability
type ReusableLinkProps = {
  to: string;
  children: React.ReactNode;
  onClick?: () => void;
};

const ReusableLink: React.FC<ReusableLinkProps> = ({
  to,
  children,
  onClick,
}) => (
  <Link
    onClick={onClick}
    to={to}
    className="font-poppins text-left text-[1.3rem] font-normal leading-[1.8rem] tracking-[0.02rem] text-[#5D5E76] no-underline"
  >
    {children}
  </Link>
);

// ContentBar component
const ContentBar = () => {
  const [open, setOpen] = useState<boolean>(false);
  const handleClick = () => {
    setOpen(true);
  };
  const { t } = useTranslation('footer');
  const [emailToSubscribe, setEmailToSubscribe] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handles subscription form submission
  const handleSubscribe = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post(`${process.env.GATSBY_BACKEND_URL}/submit` || '', {
        formId: formIds.SUBSCRIBE_FORM,
        responses: {
          email: emailToSubscribe,
          name: 'N/A',
        },
      });
      toast.success(
        t('footer.contentBar.subscriptionSuccess', 'Subscribed successfully!')
      );
    } catch {
      toast.error(
        t(
          'footer.contentBar.subscriptionError',
          'Something went wrong, please try again later.'
        )
      );
    } finally {
      setEmailToSubscribe('');
      setIsSubmitting(false);
    }
  };

  return (
    <div className="grid grid-cols-1 py-[3rem] sm:grid-cols-[6fr,4fr] sm:py-[6rem] lg:grid-cols-[6fr,6fr,4fr]">
      {/* Logo and Mission Section */}
      <div className="flex flex-col justify-start sm:mb-[5rem]">
        <XlLogo width="20rem" />
        <p className="font-poppins mt-[4rem] w-full text-start text-[1.4rem] font-normal leading-[3rem] tracking-wider text-[#585B63]">
          {t(
            'footer.contentBar.mission',
            'Suwa Diviya is dedicated to combating diabetes through a comprehensive approach that focuses on prevention, early diagnosis, and adequate control to prevent complications.'
          )}
        </p>
      </div>

      {/* Navigation Links Section */}
      <div className="my-[4rem] flex lg:my-0">
        <div className="mx-auto flex flex-col justify-center text-start">
          <h1 className="font-poppins mb-[1.5rem] text-left text-[1.6rem] font-semibold leading-[3rem] text-base-black">
            {t('footer.contentBar.overview.title', 'Overview')}
          </h1>
          <div className="flex flex-col gap-2">
            <ReusableLink to="/about-us">
              {t('footer.contentBar.overview.aboutUs', 'About Us')}
            </ReusableLink>
            <ReusableLink to="/about-diabetes">
              {t('footer.contentBar.overview.aboutDiabetes', 'About Diabetes')}
            </ReusableLink>
            <ReusableLink to="/programs">
              {t('footer.contentBar.overview.ourPrograms', 'Our Programs')}
            </ReusableLink>
            <ReusableLink to="/library">
              {t('footer.contentBar.overview.mediaHub', 'Media Hub')}
            </ReusableLink>
          </div>
        </div>
        <div className="mx-auto flex flex-col justify-center text-start">
          <h1 className="font-poppins mb-[1.5rem] text-left text-[1.6rem] font-semibold leading-[3rem] text-base-black">
            {t('footer.contentBar.support.title', 'Support')}
          </h1>
          <div className="flex flex-col gap-2">
            <ReusableLink to="/contact-us">
              {t('footer.contentBar.support.general', 'General FAQ’s')}
            </ReusableLink>
            <ReusableLink to="/about-us">
              {t('footer.contentBar.support.resources', 'Resources')}
            </ReusableLink>
            <ReusableLink to="/contact-us">
              {t('footer.contentBar.support.contactUs', 'Contact Us')}
            </ReusableLink>
            <ReusableLink to="/" onClick={handleClick}>
              {t('footer.contentBar.support.book', 'Book a Free Program')}
            </ReusableLink>
          </div>
        </div>
        <BookFreeProgram open={open} setOpen={setOpen} />
      </div>

      {/* Subscription Form Section */}
      <div className="flex h-1/2 items-center justify-center sm:col-span-full lg:col-auto">
        <form onSubmit={handleSubscribe}>
          <div className="flex w-[39.2rem] items-center justify-center rounded-lg border border-[#ECEFF3] p-[0.6rem]">
            <MdEmail color="#8588A6" fontSize={20} />
            <div className="ml-[1.2rem] w-[21.5rem]">
              <input
                type="email"
                placeholder={t(
                  'footer.contentBar.subscribe.placeholder',
                  'Enter your Email'
                )}
                className="text-[1.2rem] outline-none"
                value={emailToSubscribe}
                onChange={(e) => setEmailToSubscribe(e.target.value)}
                required
              />
            </div>
            <CustomButton
              borderRadius="4px"
              width="12.1rem"
              height="3.2rem"
              backgroundColor="#FAA61A"
              disabled={!emailToSubscribe || isSubmitting}
              type="submit"
            >
              <p className="font-poppins flex items-center justify-center text-center text-[1.2rem] font-medium leading-[2rem] tracking-[0.02rem] text-base-white">
                {isSubmitting ? (
                  <FaSpinner className="animate-spin" />
                ) : (
                  t('footer.contentBar.subscribe.button', 'Register')
                )}
              </p>
            </CustomButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContentBar;
