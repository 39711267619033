import React, { useState } from 'react';

type Props = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  type?: 'submit' | 'reset' | 'button' | undefined;
  width?: string;
  height?: string;
  borderRadius?: 'low' | 'high' | string;
  borderWidth?: string;
  borderColor?: string;
  backgroundColor?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isValid?: boolean;
  disabled?: boolean;
  color?: string;
  fontWeight?: string;
  padding?: string | number;
};

const CustomButton = ({
  children,
  type = 'button',
  width = 'auto',
  height = 'auto',
  borderRadius = 'low',
  borderWidth = '2px',
  borderColor = '#FAA61A',
  backgroundColor = 'transparent',
  onClick,
  isValid = true,
  disabled = false,
  color = 'black',
  fontWeight = '500',
  padding = 0,
}: Props) => {
  const [isClicked, setIsClicked] = useState(false);

  const getBorderRadius = (radius: 'low' | 'high' | string): string => {
    switch (radius) {
      case 'low':
        return '1.1rem';
      case 'high':
        return '2.2rem';
      default:
        if (/^[0-9]+(%|px|em|rem|vh|vw)$/.test(radius)) {
          return radius;
        }
        if (/^[0-9]+$/.test(radius)) {
          return `${radius}px`;
        }
        return '1.1rem';
    }
  };

  const buttonStyle: React.CSSProperties = {
    width,
    height,
    padding: padding ? padding : '0',
    borderRadius: getBorderRadius(borderRadius),
    border: `${borderWidth} solid ${borderColor}`,
    backgroundColor: backgroundColor,
    cursor: disabled ? 'default': 'pointer',
    transition: 'all 0.3s ease',
    transform: isClicked ? 'scale(0.95)' : 'scale(1)',
    boxShadow: isClicked ? 'inset 0 0 10px rgba(0, 0, 0, 0.2)' : 'none',
    opacity: !isValid || disabled ? '0.5' : '1',
    fontWeight: fontWeight,
    color: color,
  };

  const handleMouseDown = () => setIsClicked(true);
  const handleMouseUp = () => setIsClicked(false);

  return (
    <button
      type={type}
      style={buttonStyle}
      className="hover:scale-105"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default CustomButton;
