import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

// Define the type for the context value
interface SectionContextType {
  section: string | undefined;
  setSection: React.Dispatch<React.SetStateAction<string | undefined>>;
}

// Create the Context
const SectionContext = createContext<SectionContextType | undefined>(undefined);

// Create a provider component
interface SectionProviderProps {
  children: ReactNode;
}

export const SectionProvider: React.FC<SectionProviderProps> = ({
  children,
}) => {
  const { t, i18n } = useTranslation('library');
  const [section, setSection] = useState<string | undefined>();

  // Update the section whenever the language changes
  useEffect(() => {
    setSection(t('library.navItems.videos', 'Videos'));
  }, [i18n.language]);

  return (
    <SectionContext.Provider value={{ section, setSection }}>
      {children}
    </SectionContext.Provider>
  );
};

// Custom hook to use the SectionContext
export const useSection = (): SectionContextType => {
  const context = useContext(SectionContext);
  if (!context) {
    throw new Error('useSection must be used within a SectionProvider');
  }
  return context;
};
