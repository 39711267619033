import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

type Props = {
  width?: string;
  height?: string;
  altText?: string;
};

const XlLogo = ({
  width = '23.4rem',
  height = '100%',
  altText = 'XL Logo',
}: Props) => {
  // Add Xmas logo if December
  const month = new Date().getMonth();
  return (
    <div style={{ width, height }}>
      {month === 11 ? (
        <StaticImage
          src={'../../../images/xmasLogoXL.svg'}
          alt={altText}
          placeholder="blurred"
        />
      ) : (
        <StaticImage
          src="../../../images/xlLogo.svg"
          alt={altText}
          placeholder="blurred"
        />
      )}
    </div>
  );
};

export default XlLogo;
