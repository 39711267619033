export const switchFont = () => {
  const language = sessionStorage.getItem('language') || 'en';
  const body = document.body;

  if (language === 'si') {
    body.style.fontFamily = "'Noto Sans Sinhala', sans-serif";
    body.setAttribute('data-language', 'si');
  } else {
    body.style.fontFamily = "'Inter', sans-serif";
    body.setAttribute('data-language', 'en');
  }
};
